<template>
  <v-card class="product-card">
    <v-card-text class="d-flex">
      <v-row>
        <v-col class="d-flex justify-center justify-lg-start" cols="12" lg="3">
          <v-img
            :src="images(item)"
            class="grey lighten-2"
            height="250"
            max-width="250"
          >
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>

        <v-col cols="12" lg="">
          <v-card-text class="pa-0 pl-lg-16">
            <v-card-title class="font-weight-bold product-title px-0 pt-0">{{ item.name }}</v-card-title>

            <v-card-subtitle class="px-0 mt-1">
              <span
                v-for="(category, index) in item.categories"
                :key="'prodcat'+index"
              >
                {{ category.name }}
                <strong v-if="(index+1) < item.categories.length"> || </strong>
              </span>
              <br>

              Sold By :
              <router-link
                :to="`/vendor/${item.shop.slug}`"
                class="text-decoration-none primary--text"
              >
                {{ item.shop.name }}
              </router-link>
            </v-card-subtitle>

            <div class="product-content price">Price : {{ item.price | currency }} /Pc</div>
            <div class="product-content count">{{ item.stock }} Pc Available</div>
          </v-card-text>

          <v-card-actions class="justify-center justify-md-start pl-0 pl-lg-16 pt-5 ">
            <div class="">
              <v-btn
                block
                color="secondary"
                @click="handleAction(item, 'cart')"
              >
                <v-icon size="16">mdi-cart</v-icon>
                Add To Cart
              </v-btn>
            </div>
          </v-card-actions>
        </v-col>
      </v-row>
      <div>
      </div>
    </v-card-text>

  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { storeHelper } from '@/utils/helper';
import { CART_ADD, cartNameSpace } from '@/store/modules/cart/types';

export default {
  name: 'horizontal-product',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  emits: ['action-clicked'],

  methods: {
    ...mapActions({
      addToCart: storeHelper(cartNameSpace, CART_ADD),
    }),

    images(item) {
      if (!item.images) return this.image; // if no images then show Default Images
      return item.images[0].url; // return first image as thumbnail
    },

    handleAction(item, action) {
      let actionName;

      switch (action) {
        case 'view' :
          actionName = 'view';
          break;

        case 'compare' :
          actionName = 'compare';
          break;

        case 'wishlist' :
          actionName = 'wishlist';
          break;

        case 'cart' :
          actionName = 'cart';
          this.addToCart(item);
          break;
      }
      this.$emit('action-clicked', { itemId: item, actionName });
    }
  }
};
</script>

<style lang="scss" scoped>

.product-content {

  &.price {
    font-weight: bold;
    font-size: 1rem;
  }

  &.count {
    font-size: 1rem;
  }

  &:first-of-type {
    margin-top: 16px;
  }

  &:last-of-type {
    //margin-bottom: 16px;
  }
}

.product-card {
  margin-bottom: 10px;

  .product-title {

    font-size: 1.6rem;
  }
}
</style>
